import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import bgPlaceholder from "../assets/images/latest-works-backgrounds/tether_black.jpg";
import { pngs, icons } from '../assets/javascript/icons'

function LatestWorksGridItem({
  columnStart,
  rowStart,
  rowEnd,
  heading,
  blurb1,
  blurb2,
  link,
  bgImg,
  iconsPng,
  iconsReact,
  bgPosition="bg-center"
}) {

  let backgroundImage = bgImg ? bgImg : bgPlaceholder

  const [textVisibility, setTextVisibility] = useState("opacity-0");
  const [bgVisbility, setBgVisbility] = useState("opacity-100");


  const itemStyles = {
    gridColumnStart: columnStart,
    gridColumnEnd: String(parseInt(columnStart) + 1),
    gridRowStart: rowStart,
    gridRowEnd: rowEnd,
  };

  const handleMouseEnter = () => {
    setTextVisibility("opacity-100");
    setBgVisbility("opacity-50")
  };
  const handleMouseLeave = () => {
    setTextVisibility("opacity-0");
    setBgVisbility("opacity-100")
  };

  return (
    <div
      className="item-base
      relative
      content-box
      border-gold-plate border-2 border-opacity-0
      rounded-xl hover:border-opacity-100
      hover:scale-105
      hover:z-10
      hover:cursor-pointer p-6 mb-4 transition-all"
      style={itemStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div className={`absolute h-full w-full ${bgPosition} bg-cover top-0 left-0 -z-10 rounded-xl ${bgVisbility} transition-all`} style={{backgroundImage: `url(${backgroundImage})`}}></div>
      <div className="">
        <h4 className="text-lg sm:text-2xl md:text-3xl my-4 font-medium border-b-2 border-b-gold-plate">
          {heading}
        </h4>
        <p className={`text-sm md:text-base py-2 ${textVisibility}`}>
          {blurb1}
        </p>
        <p className={`text-sm md:text-base py-2 ${textVisibility}`}>
          {blurb2}
        </p>
        {link ? (
          <p className={`underline underline-offset-2 my-8 ${textVisibility}`}>
            <Link to={link}>
              Read More <BsArrowRightShort className="inline" />
            </Link>
          </p>
        ) : (
          ""
        )}
        {iconsPng.map((key) => {
          return (
            <div className="tooltip" data-tip={`${key}`}>
              <img src={pngs[key]} alt="" key={`${heading}-${key}-png` } className={`w-8 m-2 inline-block ${textVisibility}` }/>
            </div>
          )
          // return <img src={el} alt="" key={el} className={`w-8 m-2 inline ${textVisibility}` }/>
        })}
        {iconsReact.map((key) => {
          return (
            <div className="tooltip" data-tip={`${key}`} key={`${heading}-${key}-icon`}>
              <span className={`inline-block ${textVisibility} `}>{icons[key]}</span>
            </div>)
        })}
      </div>
    </div>
  );
}

export default LatestWorksGridItem;
