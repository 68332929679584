import React from "react";
import Button from "../components/Button";
// import HeroImagesGrid from '../components/HeroImagesGrid';
import HeroImageBg from "../assets/images/hero_image_bg.webp";
import HeroServicesItem from "../components/HeroServicesItem";

function Hero() {
  return (
    <div>
      <div
        className="relative py-44 bg-cover bg-center bg-no-repeat "
        style={{ backgroundImage: `url(${HeroImageBg})` }}
      >
        <section className="">
          <h1 className="text-3xl sm:text-4xl md:text-5xl bg-hero lg:text-6xl xl:text-7xl py-4 w-3/4 sm:mb-8  ">
            End to End Embedded Systems Development
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl py-4 md:my-4 hero-blurb w-3/4 sm:w-1/2 sm:mb-8 ">
            Providing embedded systems development contracting to help hardware
            companies solve difficult problems, get unstuck and build
            exceptional products.
          </p>
          <div className="flex flex-wrap sm:flex-nowrap sm:mb-8">
            <Button
              link="#latest-works"
              hashLink="true"
              text="My Works"
              filled={true}
              type={false}
            />
            <Button
              link="#services"
              hashLink="true"
              text="My Services"
              filled={false}
              type={false}
            />
          </div>
        </section>


        <div className="mask-overlay absolute -z-20"></div>
      </div>
      <div className="flex flex-col items-center lg:flex-row flex-wrap md:flex-nowrap lg:justify-around mx-8 md:mx-40">
        <HeroServicesItem label="Embedded Firmware" />
        <HeroServicesItem label="Hardware Design" />
        <HeroServicesItem label="Low Volume Assembly" />
      </div>
      <div id='spacer' className="mb-20"></div>
    </div>
  );
}

export default Hero;
