import React, { useEffect, useState } from "react";
import SectionHeader from "../components/SectionHeader";
import SectionDivider from "../components/SectionDivider";
import LatestWorksGridItem from "../components/LatestWorksGridItem";

import placeholderBg from '../assets/images/latest-works-backgrounds/bg-placeholder-3.png'
import nautilus from '../assets/images/latest-works-backgrounds/nautilus.jpg'
import antennaMatching from '../assets/images/latest-works-backgrounds/antenna-matching.png'
import tetherEnviroq from '../assets/images/latest-works-backgrounds/tether_enviroq.png'
import canmaster from '../assets/images/latest-works-backgrounds/canmaster_wide.png'
import ethernet from '../assets/images/latest-works-backgrounds/ethernet.jpg'
import supercap from '../assets/images/latest-works-backgrounds/supercap.jpg'
import flowmeter from '../assets/images/latest-works-backgrounds/flowmeter.jpg'
import rfProgrammer from '../assets/images/latest-works-backgrounds/rf-programmer.jpg'
import snapperMx8 from '../assets/images/latest-works-backgrounds/snapper-mx8.jpg'
import thermostat from '../assets/images/latest-works-backgrounds/thermostat.png'
import snapperCarrier from '../assets/images/latest-works-backgrounds/snapper-carrier.png'
import ethernet4 from '../assets/images/latest-works-backgrounds/4ch-ethernet.png'
import loraNode from '../assets/images/latest-works-backgrounds/lora-node.png'
import cicada from '../assets/images/latest-works-backgrounds/cicada.png'

const LATEST_WORKS = [
  {
    heading: "Nautilus test fixture",
    blurb1: "This is an internal product development to serve as a backbone for product testing, with the goal of greatly reducing the amount of development required to construct end-of-line test fixtures. It features 12x 30V 16-bit ADCs, 12x 30V 12-bit DAC's, and two 0-24V, 0-3A adjustable power supplies. It has auxillary IO for controlling relays or serial comms to downstream equipment. It is controlled via SCPI commands via USB or Ethernet.",
    blurb2: "I developed the hardware and firmware, with colleagues helping to refine the requirements.",
    size: 3,
    bgImg: nautilus,
    iconsPng: ['Ethernet', 'CAN Bus'],
    iconsReact: ['ST Micro', 'USB', 'Signal Processing', 'Manufacturing']
  },
  {
    heading: "Gigabit ethernet mainboard",
    blurb1: "This is a mainboad for an aerosapce customer. It features a 4 port gigabit ethernet controller, and a suite of IO.",
    blurb2: "This was a redesign of an existing product, and needed to meet the same mechanical and electrical interface requrirements while providing a significant performance improvement. The key challenge was finding components that met the tight criteria while being reliably sourcable over the product life.",
    size: 1,
    bgImg: ethernet4,
    iconsPng: ['Ethernet'],
    iconsReact: ['High speed'],
    bgPosition: 'bg-bottom',
  },
  {
    heading: "ZIP LoRaWAN node",
    blurb1: "A pre-existing product developed by Zero Invasive Predators, this device monitors remote predator traps, and reports their status via LoRa radio. The goal of this project was to upgrade this device to be fully LoRaWAN compatible.",
    blurb2: "I rewrote the firmware to incorporate the LoRaWAN stack. Supporting the pre-existing component selection was a significant chllenge as a high degree of firmware optimisation was required to fit within the 64kB flash memory. Additionally support for the LoRa transciever was added to the existing vendor stack.",
    size: 2,
    bgImg: loraNode,
    iconsPng: ['LORAWAN'],
    iconsReact: ['ST Micro', 'Battery Management'],
    bgPosition: 'bg-bottom',
  },
  {
    heading: "Battery load controller",
    blurb1: "This product inforporated a discrete ESP32 design for wireless configuration. It featured high current load switching, as well as RS485 & CAN Bus communications.",
    blurb2: "This was a review and update to an existing design. I consolidated components and revised sections to reduce the board cost and complexity without impacting the protection or reliability.",
    size: 1,
    bgImg: placeholderBg,
    iconsPng: [],
    iconsReact: ['Espressif']
  },
  {
    heading: "Cicada IoT Monitor",
    blurb1: "A highly configurable IoT board for remote monitoring. It features digital, voltage, and 4-20mA inputs which are reported via NB IoT or CAT M1. New firmware or configuration can be uploaded by USB MTP to simplify deployment. It also features battery charging and can source 24V to downstream devices.",
    blurb2: "This was developed to replace an existing design. The goal was to minimize the amount of external components and reduce the maintenance burden.",
    size: 2,
    bgImg: cicada,
    iconsPng: ['NB IoT'],
    iconsReact: ['ST Micro', 'USB', 'Battery Management', 'Manufacturing']
  },
  {
    heading: "DesignA Snapper Carrier",
    blurb1: "A carrier board for evaluation of the i.MX 8 Snapper module. This provides phys and connectors for all of the modules features, including USB 3.0, gigabit ethernet, HDMI, CSI, DSI, and low speed GPIO and serial interfaces.",
    blurb2: "I did the hardware design and layout according to the requirements provided by the DesignA team.",
    size: 3,
    bgImg: snapperCarrier,
    iconsPng: ['Ethernet'],
    iconsReact: ['USB', 'High speed']
  },
  {
    heading: "Thermostat controller",
    blurb1: "A board for monitoring temperature sensors and switching a high power mains load. This included an ESP32 for wireless control and redundant layers of isolation.",
    blurb2: "I designed the board to the customers requirements, as well as assembled and tested a prototype batch.",
    size: 1,
    bgImg: thermostat,
    iconsPng: [],
    iconsReact: ['Espressif']
  },
  {
    heading: "Gould Instruments Flowmeter",
    blurb1: "An industrial flow and pressure meter, this device needed to power and interface with external industrial sensors, while providing an easy user interface for measurement and calibration. It features a graphical LCD display and firmware updates via USB.",
    blurb2: "I did the hardware and firmware development, and organised contractors for the mechanical and industrial design. I manufactured and tested their first run of hardware to provide the customer wit their first batch of devices.",
    size: 3,
    bgImg: flowmeter,
    iconsPng: [],
    iconsReact: ['ST Micro', 'Battery Management', 'USB', 'Signal Processing', 'Manufacturing']
  },
  {
    heading: "Compact Programming Jig",
    blurb1: "This board replaced an existing end-of-line programming fixture with a single board solution providing UART, JTAG, and power via USB-C.",
    blurb2: "I worked closely with the client's software team to develop the hardware based on their requirements, as well as doing a small assembly run.",
    size: 4,
    bgImg: rfProgrammer,
    iconsPng: [],
    iconsReact: ['USB', 'Manufacturing']
  },
  {
    heading: "DesignA Snapper MX8",
    blurb1: "This product was a SOM, based around an i.MX 8 application processor. This design had many high speed digital interfaces, which needed to be incorporated, including: LPDDR4 memory, EMMC, WiFi, LVDS, HDMI, USB3, RGMII, and more.",
    blurb2: "I did the hardware design and layout, working with the DesignA team who provided requirements. Yielding an improvement in performance and capability, while still maintaining backwards compatability with a previous product was the main challenge.",
    size: 5,
    bgImg: snapperMx8,
    iconsPng: [],
    iconsReact: ['NXP', 'USB', 'High speed']
  },
  {
    heading: "Supercap Charger",
    blurb1: "For an application where both peak and ultra low standby currents were required, I designed a super capacitor pack to replace the device's existing battery pack.",
    blurb2: "This device trickle charges a supercapacitor bank from high density lithium cells. This board implements balancing and discharge protection to prevent supercap degradation. A coulomb counter was also implemented to indicate consumed charge to the downstream device.",
    size: 5,
    bgImg: supercap,
    iconsPng: [],
    iconsReact: ['ST Micro', 'Battery Management']
  },
  {
    heading: "RF Antenna Matching",
    blurb1: "After a mechanical change, this product required a new antenna matching circuit to optimize radio performance.",
    blurb2: "The radio operated in the 900MHz range, had a T matching network provisioned for tuning. I used my vector network analyzer to evaluate the existing match, and iterated on it to achieve a new match. The match was then tested on several units to confirm stability of the solution.",
    size: 3,
    bgImg: antennaMatching,
    iconsPng: [],
    iconsReact: ['RF Design']
  },
  {
    heading: "Heater Processor Refit",
    blurb1: "An existing product in need of component alternatives due to sourcing issues.",
    blurb2: "I researched an alternative processor, with the goal of requiring no copper change on the PCB. Once selected, I recompiled and optimized the firmware to operate on the new target processor.",
    size: 3,
    bgImg: placeholderBg,
    iconsPng: [],
    iconsReact: ['ST Micro'],
  },
  {
    heading: "Serial to Ethernet Adaptor",
    blurb1: "An internal product development, this adaptor was created to provide an ethernet interface for equipment with a TTL serial interface.",
    blurb2: "Based around the W5500 IC, it implements TCP sockets for remote serial communication, a UDP broadcast mechanism for device discovery, and DHCP IP assignment.",
    size: 3,
    bgImg: ethernet,
    iconsPng: ['Ethernet'],
    iconsReact: ['ST Micro']
  },
  {
    heading: "Tether EnviroQ",
    blurb1: "An indoor environment monitoring system for building health monitoring, integrating CO2, temperature, humidity, pressure, light, and SPL sensors, and utilizing the Sigfox network to report device status to a custom cloud backend.",
    blurb2: "Taking responsibility for maintenance and ongoing product improvements, I implemented new low power optimisations, sound pressure level monitoring and as well as undertaking multiple revisions for hardware and firmware for component obsolescence.",
    size: 5,
    bgImg: tetherEnviroq,
    iconsPng: ['SigFox', 'LORAWAN'],
    iconsReact: ['ST Micro', 'Battery Management'],
    bgPosition: 'bg-bottom'
  },
  {
    heading: "CANmaster",
    blurb1: "An internal product development, CANmaster was created to be a more robust USB to CAN adapter.",
    blurb2: "As a USB Communications Device Class to CAN serial converter with configurable CAN bus terminator and bitrate, it works with both Windows and Linux without the need for custom driver developments.",
    size: 3,
    bgImg: canmaster,
    iconsPng: ['CAN Bus'],
    iconsReact: ['ST Micro', 'USB', 'Manufacturing']
  },
  {
    heading: "IoT Bait Monitor",
    blurb1: "A remote IoT predator bait monitoring system with strong low power requirements. I had responsibility for the firmware as well as a PCB revision.",
    blurb2: "Key areas tech areas included low power optimisation, incorporating NB-IoT and Sigfox comms, as well various sensors including Laser ToF, PIR, and impact detection via accelerometers.",
    size: 4,
    bgImg: placeholderBg,
    iconsPng: ['SigFox', 'NB IoT'],
    iconsReact: ['ST Micro', 'Battery Management']
  },
  {
    heading: "Industrial Motor Soft-Starter",
    blurb1: "A PLC controlled industrial conveyor belt controller used in airport and logistics sectors. The system was designed specifically to be plug-and-play with fast, more cost effective on site installation in mind.",
    blurb2: "In working with the client team, I was responsible for the entirety of firmware development including two-phase soft-starting algorithms, current monitoring with fault detection and USB mass storage, as well as integrating both AS-I plus PROFInet and creating a custom bootloader.",
    size: 5,
    bgImg: placeholderBg,
    iconsPng: ['Ethernet'],
    iconsReact: ['ST Micro', 'Control Systems', 'USB'],
  },
  {
    heading: "Mobile Scale Monitor",
    blurb1: "A Bluetooth LE monitoring device for sets of mobile scales, reporting measured readings back to a BLE base station.",
    blurb2: "I was responsible for developing the firmware, BLE based reporting and configuration, load cell and temperature sensor  integration.",
    size: 3,
    bgImg: placeholderBg,
    iconsPng: [],
    iconsReact: ['Nordic', 'Battery Management', 'Bluetooth'],
  },
  {
    heading: "IoT ToF Ranging Sensors",
    blurb1: "An IoT level measurement device utilizing ultrasonic time-of-flight sensors to measure distance.",
    blurb2: "As an IoT device with a five year installed lifetime, low power consumption was a critical factor to factor into software design. I was responsible for firmware incorporating lower power optimisations, battery conditioning, Sigfox comms, ultrasonic ranging algorithms as well as creating production test fixtures.",
    size: 3,
    bgImg: placeholderBg,
    iconsPng: ['SigFox', 'LORAWAN'],
    iconsReact: ['ST Micro', 'Battery Management', 'Signal Processing'],
  },
]


function LatestWorks() {

  const [layout, setLayout] = useState("grid-container");

  function handleResize() {
    if (window.innerWidth <= 1024) {
      setLayout("");
    } else {
      setLayout("grid-container");
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
  }, []);

  const columns = 3;
  var row_depth = [0, 0, 0];

  function createGridItem(item) {

    let column = 0;
    let row_start = row_depth[0];
    for (let col = 1; col < columns; col++) {
      if (row_depth[col] < row_start) {
        row_start = row_depth[col]
        column = col;
      }
    }
    
    row_depth[column] += item.size

    return <LatestWorksGridItem
      columnStart={column + 1}
      rowStart={row_start + 1}
      rowEnd={row_start + item.size + 1}
      heading={item.heading}
      blurb1={item.blurb1}
      blurb2={item.blurb2}
      bgImg={item.bgImg}
      iconsPng={item.iconsPng}
      iconsReact={item.iconsReact}
      bgPosition={item.bgPosition}
    />
  }

  return (
    <section id="latest-works">
      <article className="mb-8">
        <div id="works-headings">
          <SectionDivider text="What I've worked on" />
          <SectionHeader heading="Latest Works" />
        </div>
        {/* Should be a grid on larger screens */}
        <div id="" className={`${layout} pt-10`}>
          { LATEST_WORKS.map( item => createGridItem(item)) }
        </div>
      </article>
    </section>
  );
}

export default LatestWorks;
