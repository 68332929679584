import canGoldPlate from '../images/logos/can-gold-plate.png'
import ethernetGoldPlate from '../images/logos/ethernet-gold-plate.png'
import loraGoldPlate from '../images/logos/lora-logo-only-gold-plate.png'
import nbiotGoldPlate from '../images/logos/nbio-text-only-gold-plate.png'
import sigFoxGoldPlate from '../images/logos/sigfox-gold-plate.png'
import { IoBatteryFullOutline, IoBluetoothOutline } from 'react-icons/io5'
import { BsGear, BsSoundwave, BsBroadcastPin } from 'react-icons/bs'
import { FaUsb, FaWaveSquare  } from 'react-icons/fa'
import { MdPrecisionManufacturing } from "react-icons/md";
import { SiStmicroelectronics, SiNordicsemiconductor, SiNxp, SiEspressif } from "react-icons/si";

const iconStyle = {className: "inline m-2 text-gold-plate", size: 30}

export const pngs = {
  "CAN Bus": canGoldPlate,
  "Ethernet": ethernetGoldPlate,
  "LORAWAN": loraGoldPlate,
  "NB IoT": nbiotGoldPlate,
  "SigFox": sigFoxGoldPlate,
}

export const icons = {
  "Battery Management": <IoBatteryFullOutline className={iconStyle.className} size={iconStyle.size}/>,
  "Bluetooth": <IoBluetoothOutline className={iconStyle.className} size={iconStyle.size}/>,
  "Control Systems": <BsGear className={iconStyle.className} size={iconStyle.size}/>,
  "USB": <FaUsb className={iconStyle.className} size={iconStyle.size}/>,
  "Signal Processing": <BsSoundwave className={iconStyle.className} size={iconStyle.size}/>,
  "RF Design": <BsBroadcastPin className={iconStyle.className} size={iconStyle.size}/>,
  "High speed": <FaWaveSquare className={iconStyle.className} size={iconStyle.size}/>,
  "Manufacturing": <MdPrecisionManufacturing className={iconStyle.className} size={iconStyle.size}/>,
  "ST Micro": <SiStmicroelectronics className={iconStyle.className} size={iconStyle.size}/>,
  "Nordic": <SiNordicsemiconductor className={iconStyle.className} size={iconStyle.size}/>,
  "NXP": <SiNxp className={iconStyle.className} size={iconStyle.size}/>,
  "Espressif": <SiEspressif className={iconStyle.className} size={iconStyle.size}/>,
}