import React from "react";

function HeroServicesItem({ label }) {
  return (
    <div className="py-1">
      <h1 className="
        peer
        mx-4 py-8 lg:py-20 text-lg md:text-xl lg:text-2xl  font-medium uppercase 
        border-opacity-0 border-gold-plate border-t-2
        hover:border-opacity-100  transition-all">
        {label}
      </h1>
      <div className=" mx-auto w-1/3 peer-hover:border-opacity-100 border-b border-opacity-0 border-gold-plate h-1"></div>
    </div>
  );
}

export default HeroServicesItem;
